import React from "react";
import CardSlider from "../components/CardSlider";
import logoBMC from "../assets/img/BMC-Logo-L.webp";
import logoBMVZ from "../assets/img/Logo_BMVZ.webp";
import logoHausLeben from "../assets/img/Logo_HausLeben.webp";
import logoVGL from "../assets/img/Logo_Verein_VGL.webp";
import imgNetzwerkBuero from "../assets/img/netzwerk_buero.webp";
import mailIcon from "../assets/img/mail_icon.webp";
import mobilIcon from "../assets/img/phone_icon.webp";
import logoDoceins from "../assets/img/Logo_Doceins.webp";
import logoMedifalk from "../assets/img/Logo_Medifalk.webp";
import logoMvzSb from "../assets/img/Logo_MVZ_SB.webp";
import logoMvzVisus from "../assets/img/Logo_MVZ_Visus.webp";
import logoEpikur from "../assets/img/Logo_Epikur.webp";
import logoKroeger from "../assets/img/Logo_Kroeger.webp";
import logoPraxisPratsch from "../assets/img/Logo_Praxis_Pratsch.webp";
import logoApothekeSchlieben from "../assets/img/Logo_Apotheke_Schlieben.webp";
import pdf from "../assets/documents/ANSBeV_Satzung.pdf";
import { HashLink } from "react-router-hash-link";

function Network() {
  const organizationList = [
    [logoHausLeben, "https://www.hausleben-kurstadtregion.de/"],
    [logoVGL, "https://www.gesundheitscampus-lausitz.de/"],
  ];

  const memberList = [
    [logoDoceins, "https://doceins.de/"],
    [logoMedifalk, "https://medifalk.de/"],
    [logoMvzSb, "https://mvz-finsterwalde.de/"],
    [logoMvzVisus, "https://www.mvz-visus.de/"],
    [logoEpikur, "https://www.epikur-zentrum.de/"],
    [logoKroeger, "https://www.medizintechnik-kroeger.de/"],
    [logoApothekeSchlieben, "https://www.linden-apotheke-schlieben.de"],
    [logoPraxisPratsch, "https://www.praxis-pratsch.de/"],
  ];

  const openPdf = () => {
    window.open(pdf);
  };

  return (
    <section id="network">
      <div id="der-verein">
        <div className="container">
          <div className="padding-block-700">
            <div className="primary-heading text-left">Der Verein</div>
            <p className="paragraph" data-type="margin-top">
              Netze geben Halt, schaffen Austausch, ermöglichen Unterstützung
              und stellen Verbindungen her. Gerade in der immer komplexer
              werdenden Gesundheitsversorgung sind Kommunikation und Kooperation
              wichtig und notwendig. Mit dieser Vision wurde 2004 das Ärztenetz
              Südbrandenburg (ANSB) gegründet und ist seitdem ein verlässlicher
              Partner für regionale Versorgungsstrukturen in der Lausitz. Neben
              dem kollegialen fachlichen Austausch wurden viele Projekte
              initiiert und Modellvorhaben ermöglicht. 2020 hat sich der ANSB
              neu strukturiert und setzt seine Arbeit als gemeinnütziger Verein
              fort. Ziel ist es vor allem, die aktuellen Herausforderungen
              gemeinsam zu meistern, Synergien in der Region zu bündeln und sich
              aktiv in den Gestaltungsprozess in der Lausitz einzubringen.
            </p>
          </div>
        </div>
      </div>

      <div className="container" data-color="gray">
        <div className="padding-block-700">
          <div className="organization-header">
            Der Verein ist Mitglied in folgenden Organisationen
          </div>
          <CardSlider Items={organizationList} maxItems={2} />
        </div>
      </div>

      <div id="das-netzwerkbuero">
        <div className="container">
          <div className="padding-block-700">
            <div className="even-areas-gap">
              <span>
                <div className="primary-heading">Das Netzwerkbüro</div>
                <div className="paragraph-wrapper">
                  <p className="paragraph">
                    Im Ärztehaus Süd in Finsterwalde hat der Verein sein
                    Netzwerkbüro mit drei Mitarbeiterinnen.
                  </p>
                  <p className="paragraph">
                    Wir stehen für <span className="fw-bold">Perspektiven</span>{" "}
                    –<span className="fw-bold">Austausch</span> –{" "}
                    <span className="fw-bold">Ideen</span>.
                  </p>
                </div>
              </span>

              <img
                className="img-round-corners"
                src={imgNetzwerkBuero}
                alt="NetzwerkBüro"></img>
            </div>

            <div className="employee-card-list">
              <div className="employee-card">
                <div className="employee-card-top" data-color="at">
                  <div className="employee-card-img-wrapper">
                    <div
                      className="employee-card-img"
                      data-type="employee_1"></div>
                  </div>
                </div>
                <div className="employee-card-bottom">
                  <div className="employee-card-heading">Andrea Trunev</div>
                  <div className="employee-card-sub-heading">
                    Koordination & Geschäftsführung
                  </div>
                  <div className="employee-card-contact">
                    <img src={mailIcon} alt="icon_home"></img>
                    <p>kontakt@an-sb.de</p>
                  </div>
                  <div className="employee-card-contact">
                    <img src={mobilIcon} alt="icon_mobil"></img>
                    <p>+49 (0) 152 31929494</p>
                  </div>
                  <p className="employee-card-info">
                    Ursprünglich Kulturwissenschaftlerin und nun schon seit fast
                    15 Jahren im Gesundheitswesen tätig. Schwerpunkte sind die
                    Themen Vernetzung, Strukturwandel und Projektentwicklung.
                  </p>
                </div>
              </div>

              <div className="employee-card">
                <div className="employee-card-top" data-color="amr">
                  <div className="employee-card-img-wrapper">
                    <div
                      className="employee-card-img"
                      data-type="employee_2"></div>
                  </div>
                </div>
                <div className="employee-card-bottom">
                  <div className="employee-card-heading">
                    Andrea Müller-Roigk
                  </div>
                  <div className="employee-card-sub-heading">
                    Casemanagement & Interprofessionalität
                  </div>
                  <div className="employee-card-contact">
                    <img src={mailIcon} alt="icon_home"></img>
                    <p>beratung@an-sb.de</p>
                  </div>
                  <div className="employee-card-contact">
                    <img src={mobilIcon} alt="icon_mobil"></img>
                    <p> +49 (0) 159 06684922</p>
                  </div>
                  <p className="employee-card-info">
                    Mit über 18 Jahren Erfahrung in Anästhesie und
                    Intensivmedizin als examinierte Krankenschwester sowie als
                    Assistentin der Pflegedirektion bringe ich umfassende
                    Fachkenntnisse in der klinischen Versorgung und im Pflege-
                    und Gesundheitsmanagement mit. Ergänzend qualifizierte ich
                    mich als Case Managerin (DGCC) und erwarb einen B.Sc. in
                    Interprofessioneller Gesundheitsversorgung. Im ANSB e.V.
                    koordiniere ich die Dialogreihe MiWi Lausitz und arbeite an
                    innovativen Versorgungskonzepten, um den
                    interprofessionellen Austausch und eine patientenzentrierte
                    Gesundheitsversorgung zu fördern.
                  </p>
                </div>
              </div>

              <div className="employee-card">
                <div className="employee-card-top" data-color="mf">
                  <div className="employee-card-img-wrapper">
                    <div
                      className="employee-card-img"
                      data-type="employee_3"></div>
                  </div>
                </div>
                <div className="employee-card-bottom">
                  <div className="employee-card-heading">Magdalena Filser</div>
                  <div className="employee-card-sub-heading">
                    Projekte & Innovationen
                  </div>
                  <div className="employee-card-contact">
                    <img src={mailIcon} alt="icon_home"></img>
                    <p>projekt@an-sb.de</p>
                  </div>
                  <div className="employee-card-contact">
                    <img src={mobilIcon} alt="icon_mobil"></img>
                    <p>+49 (0) 176 40565811</p>
                  </div>
                  <p className="employee-card-info">
                    Durch mein Master-Studium in Sport- und
                    Ernährungswissenschaften an der MLU Halle (Saale) konnte ich
                    in Gesundheitszentren, einer diabetologischen Arztpraxis und
                    im Uniklinikum Dresden wertvolle Erfahrungen im
                    Gesundheitssektor sammeln. Geprägt hat mich meine Tätigkeit
                    in der Entwicklung digitaler Gesundheitsanwendungen. Seit
                    April 2023 kann ich in der Projektkoordination beim ANSB
                    e.V. meine Erfahrungen einbringen und Ideen verwirklichen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" data-color="green">
        <div className="padding-block-700">
          <div className="divider-text" data-type="inverted">
            <span className="fw-bold">
              “Zusammenkommen ist ein Beginn, Zusammenbleiben ist ein
              Fortschritt, Zusammenarbeiten ist ein Erfolg.”
            </span>
            <span> Henry Ford</span>
          </div>
        </div>
      </div>

      <div id="die-mitglieder">
        <div className="container">
          <div className="padding-block-700">
            <div className="member-wrapper">
              <div className="primary-heading">Die Mitglieder</div>
              <div className="member-info">
                Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige
                Zwecke. Der&nbsp;
                <span onClick={openPdf} className="link">
                  Satzungszweck
                </span>
                &nbsp;wird insbesondere durch die Schaffung von Strukturen zur
                Verbesserung der medizinischen Qualität in der ambulanten
                ärztlichen Versorgung der Region Südbrandenburg im Sinne einer
                interdisziplinären, multi-professionellen und
                sektorenübergreifenden Versorgung verwirklicht.
              </div>
              <div className="member-info">
                Mitglieder des Vereins können Ärzte und Psychotherapeuten
                werden, die in Südbrandenburg ambulant ärztlich bzw.
                psychotherapeutisch tätig sind. Weiterhin können natürliche,
                juristische Personen oder Personengesellschaften Mitglieder
                werden, die sich zu den Zielen des Vereins bekennen und den
                Verein bei der Erfüllung seiner satzungsgemäßen Aufgaben
                unterstützen wollen.
              </div>
            </div>
          </div>
        </div>

        <div className="container" data-color="gray">
          <div className="padding-block-700">
            <div className="organization-header">Unsere Mitglieder</div>
            <CardSlider Items={memberList} maxItems={8} />
          </div>
        </div>
        <div className="container">
          <div className="padding-block-700">
            <div className="member-wrapper">
              <div className="member-contact-info">
                Wenn Sie auch Interesse an einer Mitgliedschaft oder
                Kooperationen haben, freuen wir uns auf Ihre{" "}
                <span className="fw-bold">Kontaktaufnahme</span>.
              </div>
              <HashLink className="btn" data-type="member" to="/kontakt">
                Kontakt
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div className="container" data-color="green">
        <div className="padding-block-700">
          <div className="divider-text" data-type="inverted">
            <span className="fw-bold">
              Wir stehen für ein gemeinsames Netzwerk!
            </span>
            <span className="divier-text-small">
              Mit Ihnen als Arzt, als Anbieter von Gesundheitsdienstleistungen
              und Unterstützer des Gesundheitswesen.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Network;
